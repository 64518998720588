<template>
  <div class="orderDetail" v-if="!loading">
    <div class="d-flex justify-content-start align-items-center mb-3">
      <div>
        <a-icon @click.prevent="() => $router.go(-1)" type="arrow-left" style="font-size: 20px; color: #000000; cursor: pointer" />
      </div>
      <div class="mx-3 orderDetail-icon">
        <order-detail-icon />
      </div>
      <div class="">
        <h3> {{ $t('menu.purchase.payment.payment_method') }}</h3>
      </div>
    </div>

    <a-row>
      <a-col :xs="24" :sm="24" :md="24" class="px-2 py-2" >
        <a-col :span="24" class="payment-information px-3 py-3">
            <div style="text-align: -webkit-center">
                <div class="title d-flex justify-content-center mb-3">
                    <div class="title-mini">
                      {{ $t('menu.purchase.payment.end_of_payment') }}
                    </div>
                    &nbsp;
                    <div class="title ml-1">
                        Rabu, 26 April 2022 - 13.00
                    <!-- {{ data && data.order_number }} -->
                    </div>
                </div>

                <div v-if="isMobileView" class="timer-otp-mobile" style="text-align: center">
                    {{time}}
                </div>
                <div v-if="!isMobileView" class="timer-otp" style="text-align: center">
                    {{time}}
                </div>
            </div>
        </a-col>

        <div v-if="!isMobileView">
          <a-col :span="24" class="payment-information px-5 py-4">
              <a-row class="title d-flex" style="flex-wrap: wrap">
                <a-col :span="8" class="title d-flex" style="flex-direction: column">
                  <div class="title-mini margin-width mb-2">
                    <img style="width: 110px; height: 50px;" :src="data.instructions.image" />
                  </div>
                </a-col>
              </a-row>
              <a-row class="title d-flex" style="flex-wrap: wrap;">
                <a-col :span="8" class="title d-flex" style="flex-direction: column;">
                  <div class="title-mini margin-width">
                    {{ $t('menu.purchase.payment.va_no') }}
                  </div>
                  <div class="title d-flex margin-width-copy">
                    <p class="title" id="testing-code">
                        {{ payment && payment.account_no }}
                    </p>
                    <a class="copy-text ml-1 mt-1" v-clipboard:copy="payment.account_no"
                      v-clipboard:success="onCopyVa"
                      v-clipboard:error="onError" style="width: 50px" >
                        <a-col :xs="6" :sm="6" :md="6">
                          <copy-icon />
                        </a-col>
                    {{ $t('menu.purchase.payment.copy') }} </a>
                  </div>
                </a-col>
                <a-col :span="8" style="flex-direction: column">
                  <div class="title-mini margin-width">
                    {{ $t('menu.purchase.payment.total_payment') }}
                  </div>
                  <div class="title d-flex margin-width-copy">
                    <p class="total-payment">
                      {{ invoice.gross_amount | currency }}
                    </p>
                    <a class="copy-text ml-1 mt-1" v-clipboard:copy="invoice.gross_amount | currency"
                      v-clipboard:success="onCopyVa"
                      v-clipboard:error="onError" style="width: 50px" >
                        <a-col :xs="6" :sm="6" :md="6">
                          <copy-icon />
                        </a-col>
                    {{ $t('menu.purchase.payment.copy') }} </a>
                  </div>
                </a-col>
                <a-col :span="8" class="detail-payment">
                  <a @click="detailPayment">
                    {{ $t('menu.purchase.payment.view_payment_detail') }}
                  </a>
                </a-col>
              </a-row>
          </a-col>
        </div>

        <div v-if="isMobileView">
          <a-col :span="24" class="payment-information px-5 py-4" style="text-align: center">
              <a-row class="title d-flex" style="justify-content: center">
                <a-col :span="8" class="title d-flex" style="flex-direction: column">
                  <div class="title-mini  mb-2">
                    <img style="width: 110px; height: 50px;" :src="imageUrl" />
                  </div>
                </a-col>
              </a-row>
              <a-col class="title d-flex" style="flex-wrap: wrap">
                <a-col :span="24" class="title d-flex" style="flex-direction: column">
                  <div class="title-mini ">
                      {{ $t('menu.purchase.payment.va_no') }}
                  </div>
                  <p class="title " id="testing-code">
                      {{ payment && payment.account_no }}
                      <a class="copy-text" v-clipboard:copy="payment.account_no"
                          v-clipboard:success="onCopyVa"
                          v-clipboard:error="onError" >
                    {{ $t('menu.purchase.payment.copy') }}</a>
                  </p>
                </a-col>
                <a-col :span="24" style="flex-direction: column">
                  <div class="title-mini">
                    {{ $t('menu.purchase.payment.total_payment') }}
                  </div>
                  <p class="total-payment">
                    {{ invoice.gross_amount | currency }}
                    <a class="copy-text" v-clipboard:copy="invoice.gross_amount | currency"
                        v-clipboard:success="onCopyTotal"
                        v-clipboard:error="onError" >
                      {{ $t('menu.purchase.payment.va_no') }}</a>
                  </p>
                </a-col>
                <a-col :span="24" class="detail-payment-mobile">
                  <a @click="detailPayment">
                    {{ $t('menu.purchase.payment.view_payment_detail') }}
                  </a>
                </a-col>
              </a-col>
          </a-col>
        </div>

        <a-col :span="24" class="payment-information px-5 pl-3 pb-2">
          <div class="intruction" :style="{ marginLeft: !isMobileView ? '8rem' : '' }">
            {{ $t('menu.purchase.payment.payment_instruction') }}
          </div>
        </a-col>

        <a-col :span="24" class="payment-information px-5">
          <a-menu
            :style="{ marginLeft: !isMobileView ? '8rem' : '', width: !isMobileView ? '80%' : '100%' }"
            :default-selected-keys="['1']"
            :open-keys.sync="openKeys"
            mode="inline"
            @click="handleClick"
            v-for="(item, index) in instruction"
            :key="index"
          >
            <a-sub-menu
              :key="index"
              @titleClick="titleClick"
              style="background: #ffffff; border-bottom-width: 1px; border-bottom: solid; border-color: #D2D2D2;"
            >
              <span slot="title" style="margin-left: -25px">
                <img style="width: 65px; height: 40px; margin-right: 20px;" :src="data.instructions.image" />
                <span>{{ item.type }} </span>
              </span>
              <a-menu-item-group v-for="(v, i) in item.content" :key="i">
                <template slot="title">
                  <ol class="ml-5">
                    <li v-html="v" />
                  </ol>
                </template>
              </a-menu-item-group>
            </a-sub-menu>
          </a-menu>
            <!-- <a-collapse :bordered="false" expandIconPosition="right" :style="{ marginLeft: !isMobileView ? '5rem' : '', marginRight: !isMobileView ? '5rem' : '' }">
              <template #expandIcon="props">
                <a-icon type="plus-square" :rotate="props.isActive ? 90 : 0" style="background: #C4C4C4; font-size: 20px; color: #ffffff" />
              </template>
              <img style="width: 30px; height: 20px;" :src="imageUrl" />
                  <a-collapse-panel key="1" header="BNI Mobile Banking" class="accordion-style">
                    <p>ini text</p>
                  </a-collapse-panel>
                  <a-collapse-panel key="2" header="ATM BNI" class="accordion-style">
                    <p>ini text</p>
                  </a-collapse-panel>
                  <a-collapse-panel key="3" header="IBANK Personal" class="accordion-style">
                    <p>ini text</p>
                  </a-collapse-panel>
            </a-collapse> -->
        </a-col>

        <a-col :span="24" class="payment-information px-5 pb-5">
          <a-col :span="24" class="confirm-payment mt-4 mb-2 d-flex align-items-center justify-content-center"  :style="{ marginRight: !isMobileView ? '5rem' : '' }">
            Ok
          </a-col>
        </a-col>

      </a-col>
    </a-row>
    <a-modal v-model="modalVisible" title="Rincian Pembayaran" :footer="null" :closable="false" @cancel="handleCancel">
      <a-row class="checkout-calculation">

        <div class="d-flex justify-content-between align-items-center">
          <div> {{ $t('purchase.checkout.total_product') }} ({{ detail ? detail.items.length : 0 }} {{ $t('purchase.checkout.item') }}) </div>
          <div style="color: #F5831F"> {{ detail.sub_total | currency }} </div>
        </div>
        <div class="mt-3 d-flex justify-content-between align-items-center">
          <div> PPN </div>
          <div style="color: #F5831F"> - </div>
          <!-- <div style="color: #F5831F"> {{ (detail.gross_total  * 11/100)  | currency }}  </div> -->
        </div>
        <div class="mt-3 d-flex justify-content-between align-items-center">
          <div> {{ $t('purchase.checkout.point_used') }} </div>
          <div style="color: #F5831F"> {{data.loyalty_usage}} </div>
        </div>

        <a-divider />

        <div class="mt-3 d-flex justify-content-between align-items-center">
          <div> {{ $t('purchase.checkout.grand_total') }} </div>
          <div class="total"> {{ detail.gross_total | currency }}</div>
        </div>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import OrderDetailIcon from '@/components/Icons/DetailOrder.vue'
import CopyIcon from '@/components/Icons/Copy.vue'
// import moment from 'moment'
// import PurchaseDetailComponent from '@/components/Purchase/Detail/index'

export default {
  name: 'purhcase.detail',
  components: {
    OrderDetailIcon,
    CopyIcon,
    // PurchaseDetailComponent
  },
  data: function () {
    return {
      data: {},
      detail: {},
      payment: null,
      invoice: null,
      countDown: 1,
      time: '',
      imageUrl: 'https://rekreartive.com/wp-content/uploads/2019/04/Logo-BNI-Bank-Negara-Indonesia-46-Vector-.png',
      modalVisible: false,
      order_date: '',
      expired_date: '',
      instruction: [],
      current: ['mail'],
      openKeys: ['sub1'],
    }
  },
  watch: {
    openKeys(val) {
    },
  },
  computed: {
    loading: function () {
      return this.$store.state.purchase.loading
    },
    isMobileView () {
      return this.$store.state.settings.isMobileView
    },
  },
  methods: {
    handleClick(e) {
    },
    titleClick(e) {
    },
    detailPayment () {
      this.modalVisible = true
    },
    display () {
      return {
        props: ['arg'],
        template: '<img style="width: 30px; height: 20px;" :src="imageUrl" />',
      }
    },
    onCopyVa (e) {
      return this.$notification.success({
        message: 'Copy Virtual Account to Clipboard Success',
      })
    },
    onCopyTotal (e) {
      return this.$notification.success({
        message: 'Copy Total Payment to Clipboard Success',
      })
    },
    onError (e) {
      return this.$notification.error({
        message: 'copy to clipboard failed',
      })
    },
    fetchOrder () {
      this.$store.dispatch('purchase/GETDETAILPAYMENT', {
        order_id: this.$route.params.order_id,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          this.data = data
          this.order_date = data.created_at
          this.expired_date = data.expired_at
          const temp = data.instructions.instructions
          const getKeys = Object.keys(temp)
          for (let i = 0; i < getKeys.length; i++) {
            const arr = [
              {
                type: getKeys[i],
                content: temp[getKeys[i]],
              },
            ]
            this.instruction.push(...arr)
          }
          this.countDownTimer()
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.$route.params.order_id,
          })
        })
        .then(({ data }) => {
          this.invoice = data
          this.payment = data.payment
          return this.$store.dispatch('purchase/GETDETAIL', {
            order_id: this.$route.params.order_id,
            channel_id: this.$store.state.app.channel_id,
          })
        })
        .then(({ data }) => {
          this.detail = data
        })
    },
    countDownTimer() {
      // const now = '2022-05-12 15:26:00'
      // const now = this.order_date
      // const nowParse = this.order_date
      const add = this.expired_date
      // const add = moment(new Date(new Date(nowParse).getTime() + 60 * 60 * 24 * 1000)).format('YYYY-MM-DD HH:mm:ss')
      const remainingTime = Date.parse(add) - Date.parse(new Date())
      const seconds = Math.floor((remainingTime / 1000) % 60)
      const minutes = Math.floor((remainingTime / 1000 / 60) % 60)
      const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24)

      this.countDown = (hours * 60 * 60) + (minutes * 60) + seconds
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.time = `${hours} : ${minutes} : ${seconds}`

          this.countDownTimer()
        }, 1000)
      } else {
        this.time = 'Waktu Habis, Silahkan Lakukan Pembelian Ulang'
      }
    },
  },
  created: function () {
    this.fetchOrder()
    // this.countDownTimer()
  },
}
</script>

<style lang="scss">
.orderDetail {
  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }

  .payment-information {
    background: #FFFFFF;
    // border-radius: 5px;

    .confirm-payment {
      height: 33px;
      background: #265C9B;
      border-radius: 5px;
      color: #FFFFFF;
      cursor: pointer;
      width: 20%;
      float: right;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .title-mini {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    .title-2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #2B2B2B;
    }

    .detail-payment {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #015CA1;
      text-align: center;
      align-self: center;
    }

    .detail-payment-mobile {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #015CA1;
    }

    .copy-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #015CA1;
      text-align: center;
    }

    .margin-width {
      margin-left: auto;
      width: 70%;
    }

    .margin-width-copy {
      margin-left: auto;
      width: 70%;
      flex-direction: row;
    }

    .total-payment {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #F5831F;
    }

    .intruction {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #015CA1;
    }

    .accordion-style {
      background: #ffffff;
      border-bottom-width: 1px;
      border-color: #D2D2D2;

      .ant-collapse-header {
        color: #000000;
      }
    }

    .status-payment {
      background: #265C9B;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .payment-method {
    background: #FFFFFF;
    border-radius: 5px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .title-2 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      color: #000000;
    }
  }

  .shipping-information {
    background: #FFFFFF;
    border-radius: 5px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .content-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #585858;
    }

    .content-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .product-information {
    background: #FFFFFF;
    border-radius: 5px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    img {
      width: 97px;
      height: 93.62px;
    }

    .product-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }

    .product-sku {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8C8C8C;
    }

    .price-1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    .price-2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }
  }
}

.timer-otp {
    color: #015CA1;
    font-size: 36px;
    background: #F4F4F4;
    width: 20%;
    border-radius: 9.00021px;
}

.timer-otp-mobile {
    color: #015CA1;
    font-size: 16px;
    background: #F4F4F4;
    width: 30%;
    border-radius: 9.00021px;
}

.checkout-calculation {
  background: #FFFFFF;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }

  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #F5831F;
  }

  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #F5831F;
  }

  .term-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
  }

  .confirm-payment {
    height: 33px;
    background: #265C9B;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

</style>
